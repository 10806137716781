<template>
  <b-card>
    <b-static-table
      :items="mappedOwners"
      :fields="ownersTableFields"
      :loading="loading"
      :button-text="$t('Nou propietari')"
      show-button
      show-search
      @button-click="onNewOwnerButtonClicked"
      @item-click="onOwnerClicked"
      @item-middle-click="onOwnerMiddleClicked"
    />
  </b-card>
</template>

<script>
import { BCard } from "bootstrap-vue";
import BStaticTable from "@foravila-core/components/b-static-table/BStaticTable.vue";
import { notifyError } from "@/utils/methods";

export default {
  components: {
    BCard,
    BStaticTable,
  },
  data() {
    return {
      ownersTableFields: [
        { key: "firstName", label: this.$t("Nom"), sortable: true },
        { key: "lastName", label: this.$t("Llinatges"), sortable: true },
        { key: "phone", label: this.$t("Telèfon"), sortable: true },
        { key: "email", label: this.$t("Email"), sortable: true },
      ],
    };
  },
  computed: {
    loading() {
      return this.$store.getters["owners/loading"];
    },
    owners() {
      return this.$store.getters["owners/owners"];
    },
    mappedOwners() {
      if (!this.owners?.length) return [];
      return this.owners.map((owner) => ({
        uuid: owner.uuid || this.$t("No definit"),
        firstName: owner.firstName || this.$t("No definit"),
        lastName: owner.lastName || this.$t("No definit"),
        phone: owner.phone || this.$t("No definit"),
        email: owner.email || this.$t("No definit"),
      }));
    },
  },
  created() {
    if (!this.owners?.length && !this.loading) this.fetchOwners();
  },
  methods: {
    fetchOwners() {
      this.$store
        .dispatch("owners/fetchOwners", { pagination: false, sort: "asc" })
        .catch(() =>
          notifyError(
            this.$t("errors.fetchOwners.title"),
            this.$t("errors.fetchOwners.description")
          )
        );
    },
    onNewOwnerButtonClicked() {
      this.$router.push({
        name: "foravila-new-owner",
      });
    },
    onOwnerClicked(ownerUuid) {
      this.$router.push({
        name: "foravila-owner-view",
        params: { ownerUuid },
      });
    },
    onOwnerMiddleClicked(ownerUuid) {
      const routeData = this.$router.resolve({
        name: "foravila-owner-view",
        params: { ownerUuid },
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>
